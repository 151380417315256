import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Travel App Template | Design A Travel App | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/travel-mobile-app/"
    metaDescription="Design your own mobile travel app with Uizard's travel app template. Express full-on creativity with your mockups and prototypes with Uizard today."
    description="
    h2:A travel app template; your passport to creating an amazing, functional travel app mockup
    <br/>
    Uizard's travel mobile app template is easily a crowd favorite. Featuring a modern and sleek design for your travel app mockup, this template will truly demonstrate Uizard's potential to be your favorite <a:https://uizard.io/mockups/>UI mockup creator</a> for all your future projects.
    <br/>
    h3:A high-quality travel app template designed to kickstart your travel project
    <br/>
    With Uizard, you get access to a growing list of <a:https://uizard.io/templates/mobile-app-templates/>mobile app templates</a> that could be the missing piece needed to streamline your design flow. Our travel app design template, for example, boasts a carefully crafted design that reflects, not only design trends, but also functional user experience, enabling you to create an effective travel app mockup faster than ever before.
    <br/>
    h3:How to create a travel app; start with the perfect template
    <br/>
    With our travel app template, all the colors, fonts, button styles, and other elements are fully customizable. Express full-on creativity by adapting it to a theme you built from scratch or make use of Uizard's AI features to generate new themes. Get this travel app template free when you sign up to Uizard today.
    "
    pages={[
      "A menu page",
      "A homepage, featuring different destinations",
      "A designated page for each destination",
      "A booking page",
      "Smooth transitions and animations all throughout",
    ]}
    projectCode="4oO4Yja0d5i7wBGrZqWy"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Travel Mobile App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Travel Mobile App: trip summary screen"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Travel Mobile App: trip details page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Travel Mobile App: menu"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Travel Mobile App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/travel-app/travel-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/travel-app/travel-app-trip-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/travel-app/travel-app-trip-detail.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/travel-app/travel-app-menu.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/travel-app/travel-app-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
